import { useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'

import Switch from '@material-ui/core/Switch'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import { projectsStatusFilter } from '@lib/apollo/apolloCache'
import {
  DARK_PURPLE,
  LIGHT_PURPLE,
  PURPLE_BEANIE_BABY,
  PURPLE_KOOSH,
  GRAY_GAMEBOY,
} from '@lib/colors'
import { DESKTOP_CENTER_SPACE, MOBILE_BREAKPOINT, MOBILE_CENTER_SPACE } from '@lib/theme'

import { PhaseSelectView } from '@components_pop/project/PhaseSelect'

import useBreakpoint from '@hooks/useBreakpoint'

import PDJProjectFilters from './PDJProjectFilters'

const useStyles = makeStyles((theme) => ({
  projectFilters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '43px',
    background: PURPLE_KOOSH,
    padding: `0 ${MOBILE_CENTER_SPACE}px`,
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      padding: `0 ${DESKTOP_CENTER_SPACE}px`,
    },
  },
  projectCategories: {
    '& a': {
      cursor: 'pointer',
    },
    display: 'flex',
    alignItems: 'center',
  },
  phaseSelect: {
    height: 37,
    marginLeft: theme.spacing(1.5),
  },
  backgroundReset: {
    backgroundImage: 'linear-gradient(#d3d3d3, #d3d3d3)',
    transition: 'none',
  },
  selected: {
    color: DARK_PURPLE,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 17,
    '&.selected,&:hover': {
      textDecoration: 'none',
      color: DARK_PURPLE,
    },
  },
  unSelected: {
    color: LIGHT_PURPLE,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 17,
    '&.selected,&:hover': {
      textDecoration: 'none',
      color: LIGHT_PURPLE,
    },
  },
}))

const AntSwitch = withStyles(() => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    margin: 10,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: LIGHT_PURPLE,
    '&$checked': {
      transform: 'translateX(12px)',
      color: LIGHT_PURPLE,
      '& + $track': {
        opacity: 1,
        backgroundColor: PURPLE_BEANIE_BABY,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 12,
    opacity: 1,
    backgroundColor: PURPLE_BEANIE_BABY,
    boxShadow: `inset 0px 0px 2px ${GRAY_GAMEBOY}`,
  },
  checked: {},
}))(Switch)

const CATEGORIES = [
  {
    value: 'NPI',
    label: 'Journey',
  },
  {
    value: 'LEGACY',
    label: 'Legacy',
  },
]

const ProjectListFilters = ({
  category,
  onCategoryChange,
  statuses,
  onProjectStatusFilterChange,
  onFiltersChange,
  filters,
  isFiltering,
}) => {
  const projectStatus = useReactiveVar(projectsStatusFilter)
  const { isDesktop } = useBreakpoint()
  const classes = useStyles()

  return (
    <div className={classes.projectFilters}>
      <div className={classes.projectCategories}>
        <a
          className={category === 'NPI' ? classes.selected : classes.unSelected}
          onClick={() => onCategoryChange('NPI')}
          data-test-id="project-filter-type-npi"
        >
          {CATEGORIES[0].label}
        </a>
        <AntSwitch
          checked={category === 'LEGACY'}
          onChange={() => onCategoryChange(category === 'NPI' ? 'LEGACY' : 'NPI')}
        />
        <a
          className={category === 'LEGACY' ? classes.selected : classes.unSelected}
          onClick={() => onCategoryChange('LEGACY')}
          data-test-id="project-filter-type-legacy"
        >
          {CATEGORIES[1].label}
        </a>
      </div>
      {isDesktop && category === 'LEGACY' && (
        <PhaseSelectView
          className={classes.phaseSelect}
          currentStatus={projectStatus}
          backgroundResetClass={classes.backgroundReset}
          onChange={onProjectStatusFilterChange}
          statuses={statuses}
          filterMode
        />
      )}
      {isDesktop && category === 'NPI' && (
        <PDJProjectFilters
          filters={filters}
          isFiltering={isFiltering}
          onFiltersChange={onFiltersChange}
        />
      )}
    </div>
  )
}

ProjectListFilters.propTypes = {
  category: PropTypes.string.isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        count: PropTypes.number,
      }),
    })
  ),
  isFiltering: PropTypes.bool,
  filters: PropTypes.object,
  onCategoryChange: PropTypes.func.isRequired,
  onProjectStatusFilterChange: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
}

export default ProjectListFilters
