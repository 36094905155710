import { useMutation, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'

import { PRIMARY_RED } from '@lib/colors'
import { NOTIFICATION_TYPE } from '@lib/constants/notifications'
import useRoute from '@lib/useRoute'

import { GET_NOTIFICATIONS_LOCAL } from '@graphql/notifications/queries'
import { CLEAR_NO_RESPONSE_SINCE } from '@graphql/project/mutators'
import { GET_PROJECTS } from '@graphql/project/queries'

import { PROJECT_DETAILS_ROUTES_NAMES } from '@components_pop/ProjectDetailsPage/utils'

import ProjectListView from './view'

const ProjectListContainer = ({ page, setPage, totalCount, projects, showingLegacy }) => {
  const [goTo] = useRoute()
  // Queries and Mutations
  const {
    data: {
      notificationsWrapper: { notifications },
    },
  } = useQuery(GET_NOTIFICATIONS_LOCAL)

  const [clearNoResponseSince] = useMutation(CLEAR_NO_RESPONSE_SINCE, {
    refetchQueries: [{ query: GET_PROJECTS }],
  })

  const responseTimeStrings = {}
  const responseTimeColors = {}

  const handleGoToProject = (projectSlug) => {
    goTo(`/project/${projectSlug}/${PROJECT_DETAILS_ROUTES_NAMES.MILESTONES}`)
  }

  const goToTeam = (projectSlug) => {
    goTo(`/project/${projectSlug}/${PROJECT_DETAILS_ROUTES_NAMES.TEAM}`)
  }

  const handleClearNoResponseSince = (projectId) => (e) => {
    e.stopPropagation()
    clearNoResponseSince({
      variables: {
        projectId,
      },
    })
  }

  projects.forEach((p) => {
    const time = p.noGembahResponseSince
    if (!time) {
      responseTimeStrings[p.id] = ''
      responseTimeColors[p.id] = 'inherit'
    } else {
      const hours = moment(time).diff(moment(), 'hours')
      const days = moment(time).diff(moment(), 'days')
      if (hours >= 16) {
        responseTimeColors[p.id] = PRIMARY_RED
      }

      responseTimeStrings[p.id] =
        // eslint-disable-next-line no-nested-ternary
        hours >= 24 ? (days === 1 ? `${days} day` : `${days} days`) : `${hours}h`
    }
  })

  const chatNotificationCounts = _.countBy(
    _.filter(notifications, (n) => {
      try {
        return [NOTIFICATION_TYPE.AT_MENTION, NOTIFICATION_TYPE.CHAT_MESSAGE].includes(
          n.notificationType
        )
      } catch (err) {
        return false
      }
    }),
    (n) => n.projectId
  )

  return (
    <ProjectListView
      page={page}
      setPage={setPage}
      totalCount={totalCount}
      projects={projects}
      chatNotificationCounts={chatNotificationCounts}
      responseTimeStrings={responseTimeStrings}
      responseTimeColors={responseTimeColors}
      onClearNoResponseSince={handleClearNoResponseSince}
      goToTeam={goToTeam}
      onGoToProject={handleGoToProject}
      showingLegacy={showingLegacy}
    />
  )
}

ProjectListContainer.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  projects: PropTypes.array.isRequired,
  showingLegacy: PropTypes.bool,
}

export default ProjectListContainer
