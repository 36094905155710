/* eslint-disable jsx-a11y/anchor-is-valid */
import { faUserClock, faBellSlash, faClock } from '@fortawesome/pro-light-svg-icons'
import { faExclamationTriangle, faFlag, faGrinBeam } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import moment from 'moment-timezone'
import Link from 'next/link'
import PropTypes from 'prop-types'
import React from 'react'

import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import ChatBubbleIcon from '@public/svg/icons/chat-bubble.svg'

import { projectsOrderBy } from '@lib/apollo/apolloCache'
import {
  PRIMARY_RED,
  BOX_BORDER_COLOR,
  THIRD_GREY_BACKGROUND,
  GOOD_STATUS,
  MEDIUM_STATUS,
  BAD_STATUS,
  TERTIARY_COLOR_MAIN,
} from '@lib/colors'
import { makeEllipsisText, MOBILE_BREAKPOINT } from '@lib/theme'
import { getBaseCDNuri } from '@lib/url'
import { hasPermission } from '@lib/userAuth'

import { PROJECT_DETAILS_ROUTES_NAMES } from '@components_pop/ProjectDetailsPage/utils'
import SvgLoader from '@components_pop/SvgLoader'
import GembahTable from '@components_pop/Table'
import { PhaseIcon } from '@components_pop/project/PhaseSelect'

import useBreakpoint from '@hooks/useBreakpoint'

import ProjectNpiExpansionContent from '../ProjectNpiExpansionContent'
import TeamMembersAvatar from '../TeamMembersAvatar'
import { ProjectStatusIcon } from './ProjectHealthIconStatus'
import { PRODUCTION_ISSUE_STATUS } from './constants'
import EmptyState from './empty'

const useStyles = makeStyles((theme) => ({
  imageHead: {
    width: 85,
  },
  productHead: {
    paddingLeft: `${theme.spacing()}px !important`,
    width: '25%',
  },
  companyHead: {
    width: '15%',
  },
  phaseHead: {
    width: 85,
  },
  statusHead: {
    width: 85,
  },
  milestoneHead: {
    width: '15%',
  },
  teamHead: {
    width: '15%',
  },
  responseHead: {
    width: 110,
  },
  activityHead: {
    width: 100,
  },
  createdHead: {
    width: 120,
  },
  actionsHead: {
    width: 40,
  },
  projectTable: {
    tableLayout: 'fixed',
    width: '100%',
    borderSpacing: `0 ${theme.spacing()}px`,
    '& thead tr th': {
      paddingLeft: 0,
    },
    '& th': {
      borderBottom: `1px solid ${BOX_BORDER_COLOR}`,
      paddingBottom: theme.spacing(),
    },
    '& td': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      '& th:nth-of-type(1)': {
        // image
        width: 75,
        [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
          width: 80,
        },
      },
    },
  },
  projectImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: 75,
    height: 75,
    border: `solid ${BOX_BORDER_COLOR} 1px`,
    margin: '0 auto',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      width: 80,
      height: 60,
    },
  },
  projectName: {
    ...makeEllipsisText(),
    color: theme.palette.text.secondary,
    fontSize: '1.1rem',
    fontWeight: 300,
    '& a': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: TERTIARY_COLOR_MAIN,
      textDecoration: 'underline',
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      fontWeight: 400,
    },
  },
  companyName: {
    ...makeEllipsisText(),
    color: theme.palette.text.secondary,
    fontWeight: 300,
    textTransform: 'uppercase',
    fontSize: '1.1rem',
    marginBottom: 4,
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      paddingRight: theme.spacing(),
      marginBottom: 0,
      color: theme.palette.text.primary,
      fontWeight: 400,
    },
  },
  projectTableRow: {
    '& td': {
      padding: 0,
      border: 'none',
      background: THIRD_GREY_BACKGROUND,
    },
  },
  projectResponseTimeWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1.1rem',
    },
    '& > svg:first-of-type': {
      display: 'none',
    },
    '& > span': {
      marginLeft: theme.spacing(1),
      fontWeight: 500,
      fontSize: '0.8rem',
    },
    '& > button': {
      padding: 0,
      display: 'none',
    },
  },
  projectResponseTimeWrapperWithReset: {
    '& > svg:first-of-type': {
      display: 'block',
    },
    '& > svg:last-of-type': {
      display: 'none',
    },
    '&:hover > button': {
      display: 'block',
    },
    '&:hover > svg': {
      display: 'none',
    },
    '&:hover > span': {
      display: 'none',
    },
  },
  badgeRoot: {
    fontSize: theme.spacing(2), // the FontAwesomeIcon size
    marginRight: 4,
    '& > div': {
      width: 18,
      height: 18,
    },
  },
  chatBubblesIcon: {
    color: PRIMARY_RED,
  },
  imageMobileCell: {
    width: 83,
  },
  projectDataMobileCell: {
    width: '70%',
  },
  phaseMobileCell: {
    width: 45,
    verticalAlign: 'top',
  },
  mobilePhaseIconContainer: {
    paddingLeft: theme.spacing(),
    paddingTop: theme.spacing(),
  },
  rowNotificationContainer: {
    display: 'flex',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  healthStatusWrapper: {
    display: 'flex',
  },
}))

const ProjectListView = ({
  page,
  setPage,
  totalCount,
  projects,
  chatNotificationCounts,
  responseTimeStrings,
  responseTimeColors,
  onClearNoResponseSince,
  onGoToProject,
  goToTeam,
  showingLegacy,
}) => {
  const classes = useStyles()
  const { isDesktop } = useBreakpoint()

  const getResponseTimeContent = (project) => {
    const time = project.noGembahResponseSince
    const responseTime = responseTimeStrings[project.id]
    const wrapperClasses = clsx({
      [classes.projectResponseTimeWrapper]: true,
      [classes.projectResponseTimeWrapperWithReset]: time !== null,
    })

    return (
      <div className={wrapperClasses}>
        <IconButton onClick={onClearNoResponseSince(project.id)}>
          <FontAwesomeIcon icon={faBellSlash} />
        </IconButton>
        <FontAwesomeIcon icon={faClock} />
        <FontAwesomeIcon icon={faUserClock} color="green" />
        <span style={{ color: responseTimeColors[project.id] }}>{responseTime}</span>
      </div>
    )
  }

  const getProjectImg = (project) =>
    project?.coverImage?.thumbnailUrl || getBaseCDNuri('no-image-placeholder.png')

  const renderRowProjectNotifications = (notificationCount) => (
    <div className={classes.rowNotificationContainer}>
      <Badge
        overlap="circular"
        badgeContent=" "
        variant="dot"
        color={notificationCount !== 0 ? 'error' : 'default'}
        classes={{ root: classes.badgeRoot }}
      >
        <SvgLoader {...ChatBubbleIcon} />
      </Badge>
      <span className={clsx({ [classes.chatBubblesIcon]: notificationCount !== 0 })}>
        {notificationCount}
      </span>
    </div>
  )

  const generateProdStatusIcon = (project) => {
    let icon

    switch (project?.productionSummary?.issueStatus) {
      case PRODUCTION_ISSUE_STATUS.GOOD:
        icon = (
          <FontAwesomeIcon
            data-test-id="project-good-health-status"
            size="lg"
            icon={faGrinBeam}
            color={GOOD_STATUS}
          />
        )
        break
      case PRODUCTION_ISSUE_STATUS.MEDIUM:
        icon = (
          <FontAwesomeIcon
            data-test-id="project-medium-health-status"
            size="lg"
            icon={faFlag}
            color={MEDIUM_STATUS}
          />
        )
        break
      case PRODUCTION_ISSUE_STATUS.BAD:
        icon = (
          <FontAwesomeIcon
            data-test-id="project-bad-health-status"
            size="lg"
            icon={faExclamationTriangle}
            color={BAD_STATUS}
          />
        )
        break
      default:
        icon = null
    }

    return icon
  }

  const buildHeaders = () => {
    const headers = [
      {
        name: 'image',
        text: 'Image',
        classNames: { [classes.imageHead]: true },
      },
      {
        name: 'name',
        text: 'Product Name',
        sortable: true,
        classNames: { [classes.productHead]: true },
      },
      {
        name: 'company__name',
        text: 'Company',
        sortable: true,
        classNames: { [classes.companyHead]: true },
      },
    ]

    if (showingLegacy) {
      headers.push(
        {
          name: '_status',
          text: 'Status',
          sortable: false,
          allowedRoles: hasPermission.GEMBAH,
          classNames: { [classes.statusHead]: true },
        },
        {
          name: '_project_status',
          text: 'Health',
          sortable: false,
          allowedRoles: hasPermission.GEMBAH,
          classNames: { [classes.statusHead]: true },
        },
        {
          name: 'status',
          text: 'Phase',
          sortable: true,
          classNames: { [classes.phaseHead]: true },
        }
      )
    } else {
      headers.push(
        {
          name: 'client_pulse__calculated_status',
          text: 'Health',
          allowedRoles: hasPermission.GEMBAH,
          sortable: true,
          classNames: { [classes.statusHead]: true },
        },
        {
          name: 'milestone',
          text: 'Milestone',
          sortable: false,
          classNames: { [classes.milestoneHead]: true },
        }
      )
    }

    headers.push(
      {
        name: 'team',
        text: 'Team',
        classNames: { [classes.teamHead]: true },
      },
      {
        name: 'no_gembah_response_since',
        text: 'Response',
        sortable: true,
        allowedRoles: hasPermission.GEMBAH,
        classNames: { [classes.responseHead]: true },
      },
      {
        name: 'activity',
        text: 'Activity',
        classNames: { [classes.activityHead]: true },
      },
      {
        name: 'created_at',
        text: 'Created',
        sortable: true,
        classNames: { [classes.createdHead]: true },
      },
      {
        name: 'actions',
        text: '',
        classNames: { [classes.actionsHead]: true },
      }
    )

    return headers
  }

  const listItemsMappingMobile = (project) => {
    const notificationCount = chatNotificationCounts[project.id] || 0

    const buildMobileCells = () => {
      const cells = [
        {
          classNames: { [classes.imageMobileCell]: true },
          content: (
            <div className={classes.projectImg}>
              <img alt={project.name} src={getProjectImg(project)} />
            </div>
          ),
        },
        {
          classNames: { [classes.projectDataMobileCell]: true },
          content: (
            <>
              <div className={classes.projectName}>{project.name}</div>
              <div className={classes.companyName}>{project.company?.name}</div>
              {renderRowProjectNotifications(notificationCount)}
            </>
          ),
        },
      ]

      if (showingLegacy) {
        cells.push({
          classNames: { [classes.phaseMobileCell]: true },
          content: (
            <div className={classes.mobilePhaseIconContainer}>
              <PhaseIcon phase={project.projectState || project.status} />
            </div>
          ),
        })
      } else {
        cells.push({
          content: (
            <div
              className={classes.healthStatusWrapper}
              data-test-id={`project-dashboard-project-list--row-${project.id}-status`}
            >
              <ProjectStatusIcon hasTooltip status={project.clientPulse?.calculatedStatus} />
            </div>
          ),
        })
      }

      return cells
    }

    return {
      row: {
        action: () => onGoToProject(project.slug),
        classNames: { [classes.projectTableRow]: true },
        key: 'name',
      },
      cells: buildMobileCells(),
    }
  }

  const listItemsMappingDesktop = (project) => {
    const notificationCount = chatNotificationCounts[project.id] || 0

    const buildDesktopCells = () => {
      const cells = [
        {
          content: (
            <div className={classes.projectImg}>
              <img alt={project.name} src={getProjectImg(project)} />
            </div>
          ),
        },
        {
          content: (
            <div className={classes.projectName}>
              <Link href={`/project/${project.slug}/${PROJECT_DETAILS_ROUTES_NAMES.MILESTONES}`}>
                <a onClick={(e) => e.stopPropagation()}>{project.name}</a>
              </Link>
            </div>
          ),
        },
        {
          content: <div className={classes.companyName}>{project.company?.name}</div>,
        },
      ]

      cells.push({
        content: (
          <div
            className={classes.healthStatusWrapper}
            data-test-id={`project-dashboard-project-list--row-${project.id}-status`}
          >
            <ProjectStatusIcon
              hasTooltip
              status={
                showingLegacy ? project.health?.status : project.clientPulse?.calculatedStatus
              }
              reason={showingLegacy ? project.health?.reason : ''}
            />
          </div>
        ),
        allowedRoles: hasPermission.GEMBAH,
      })

      if (showingLegacy) {
        cells.push(
          {
            content: (
              <div data-test-id={`project-dashboard-project-list--row-${project.id}-health`}>
                <Tooltip
                  placement="top"
                  title={
                    project?.productionSummary?.issueStatus === PRODUCTION_ISSUE_STATUS.BAD
                      ? project?.productionSummary?.issueCategory || ''
                      : project?.productionSummary?.status || ''
                  }
                  arrow
                >
                  <span>{generateProdStatusIcon(project)}</span>
                </Tooltip>
              </div>
            ),
            allowedRoles: hasPermission.GEMBAH,
          },
          {
            content: (
              <Tooltip
                placement="bottom-start"
                title={project.projectState ?? project.status ?? ''}
              >
                <span style={{ display: 'block' }}>
                  <PhaseIcon phase={project.projectState || project.status} />
                </span>
              </Tooltip>
            ),
          }
        )
      } else {
        cells.push({
          content: (
            <Tooltip placement="top" title={project.currentNpiMilestone?.code ?? '-'} arrow>
              <span>{project.currentNpiMilestone?.name ?? '-'}</span>
            </Tooltip>
          ),
        })
      }

      cells.push(
        {
          content: <TeamMembersAvatar project={project} goToTeam={goToTeam} />,
        },
        {
          content: getResponseTimeContent(project),
          allowedRoles: hasPermission.GEMBAH,
        },
        {
          content: renderRowProjectNotifications(notificationCount),
        },
        {
          content: moment(project.createdAt).format('MM/DD/YYYY'),
        }
      )

      return cells
    }

    return {
      row: {
        action: () => onGoToProject(project.slug),
        classNames: { [classes.projectTableRow]: true },
        key: 'name',
      },
      cells: buildDesktopCells(),
    }
  }

  return (
    <GembahTable
      orderByVar={projectsOrderBy}
      tableClassNames={{ [classes.projectTable]: true }}
      tableName="project-dashboard-project"
      headers={buildHeaders()}
      emptyState={<EmptyState />}
      listItems={projects}
      listItemsMap={(project) =>
        isDesktop ? listItemsMappingDesktop(project) : listItemsMappingMobile(project)
      }
      paginationProps={{
        page,
        totalCount,
        onChange: setPage,
      }}
      WithExpansionContent={ProjectNpiExpansionContent}
    />
  )
}

ProjectListView.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  projects: PropTypes.array.isRequired,
  chatNotificationCounts: PropTypes.object.isRequired,
  responseTimeStrings: PropTypes.object.isRequired,
  responseTimeColors: PropTypes.object.isRequired,
  onClearNoResponseSince: PropTypes.func.isRequired,
  onGoToProject: PropTypes.func.isRequired,
  goToTeam: PropTypes.func.isRequired,
  showingLegacy: PropTypes.bool,
}

export default ProjectListView
