import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'

import AddIcon from '@public/svg/icons/add.svg'

import { COMMON_WHITE, FOURTH_GREY } from '@lib/colors'
import { serializeData } from '@lib/tracking'
import { hasPermission } from '@lib/userAuth'
import withPerms from '@lib/withPerms'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  projectTeamAvatars: {
    display: 'flex',
  },
  projectTeamAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '0.80rem',
    border: `1px solid ${COMMON_WHITE}`,
    marginRight: -theme.spacing(1),
  },
  projectTeamAvatarAdd: {
    fontSize: '1.8rem',
    fontWeight: 300,
    '& > div': {
      width: 32,
      height: 32,
    },
  },
  projectTeamAvatarOther: {
    background: FOURTH_GREY,
  },
}))

const TeamMembersAvatar = ({ project, goToTeam, count = 3 }) => {
  const classes = useStyles()
  const navigateToTeam = (add) => (e) => {
    e.stopPropagation()
    goToTeam(project.slug, add)
  }

  const AddTeamMember = () => (
    <Avatar
      data-test-id={`project-dashboard-project-list--row-${project.id}-add-team-member`}
      onClick={navigateToTeam(true)}
      className={clsx(classes.projectTeamAvatar, classes.projectTeamAvatarAdd)}
      data-tracking-info={serializeData({
        id: 'projects-list_add-team-member_click',
        projectId: project.slug,
      })}
    >
      <SvgLoader {...AddIcon} />
    </Avatar>
  )

  const ProtectedAddTeamMember = withPerms(AddTeamMember)

  return (
    <div
      className={classes.projectTeamAvatars}
      data-tracking-info={serializeData({
        id: 'projects-list_view-team_click',
        projectId: project.slug,
      })}
    >
      {project.team.slice(0, count).map((member) => (
        <Avatar
          className={classes.projectTeamAvatar}
          key={`${project.id}-${member.id}`}
          alt={`${member.firstName} ${member.lastName}`}
          src={member.profilePicture?.url}
        >
          {`${member.firstName?.[0] || ''}${member.lastName?.[0] || ''}`}
        </Avatar>
      ))}
      {project.team.length - count > 0 && (
        <Avatar
          onClick={navigateToTeam()}
          className={clsx(classes.projectTeamAvatar, classes.projectTeamAvatarOther)}
        >
          +{project.team.length - count}
        </Avatar>
      )}

      <ProtectedAddTeamMember permissionTest={hasPermission.GEMBAH_ADMIN} />
    </div>
  )
}

TeamMembersAvatar.propTypes = {
  project: PropTypes.object.isRequired,
  count: PropTypes.number,
  goToTeam: PropTypes.func.isRequired,
}

export default TeamMembersAvatar
