import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { currentAccountUser } from '@lib/apollo/apolloCache'
import { BRAND_WHITE, LIGHT_PURPLE } from '@lib/colors'
import { hasPermission } from '@lib/userAuth'

import ProjectFilter from './ProjectFilter'

const useStyles = makeStyles(() => ({
  pdjProjectFilters: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: '14px',
  },
  projectFilters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& p': {
      marginLeft: '5px',
      wordSpacing: '0.1px',
    },
    '& > div': {
      width: 15,
      height: 15,
    },
  },
  resetFiltersHidden: {
    visibility: 'hidden',
    opacity: 0,
  },
  resetFilters: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: LIGHT_PURPLE,
    fontWeight: 700,
    lineHeight: '30px',
    padding: '0px 18px',
    gap: '10px',
    background: BRAND_WHITE,
    borderRadius: 8,
    marginLeft: 30,
    '& > div': {
      width: 15,
      height: 15,
    },
  },
}))

const PDJProjectFiltersView = ({
  milestones,
  healths,
  statuses,
  regions,
  complexities,
  filters,
  isFiltering,
  onFilterChange,
  onResetFilters,
}) => {
  const accountUser = useReactiveVar(currentAccountUser)
  const isExternal = hasPermission.EXTERNAL(accountUser)

  const projectFilters = [
    { title: 'Milestone', key: 'milestones', items: milestones, filter: filters.milestones },
    { title: 'Health', key: 'health', items: healths, filter: filters.health, hidden: isExternal },
    { title: 'Status', key: 'status', items: statuses, filter: filters.status },
    { title: 'Region', key: 'region', items: regions, filter: filters.region },
    { title: 'Complexity', key: 'complexity', items: complexities, filter: filters.complexity },
  ]

  const classes = useStyles()

  const hasFilter = Object.values(filters).some((filter) => !!filter.length)

  return (
    <div className={classes.pdjProjectFilters}>
      <div className={classes.projectFilters}>
        {projectFilters
          .filter((f) => !f.hidden)
          .map((f) => (
            <ProjectFilter
              key={f.key}
              title={f.title}
              items={f.items}
              filter={f.filter}
              isFiltering={isFiltering}
              onFilterChange={(v) => onFilterChange(f.key, v)}
            />
          ))}
      </div>
      <div
        className={clsx(classes.resetFilters, { [classes.resetFiltersHidden]: !hasFilter })}
        onClick={() => {
          if (isFiltering) return
          onResetFilters()
        }}
      >
        clear filters
      </div>
    </div>
  )
}

const labelValuePropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

PDJProjectFiltersView.propTypes = {
  milestones: PropTypes.arrayOf(labelValuePropType),
  healths: PropTypes.arrayOf(labelValuePropType).isRequired,
  statuses: PropTypes.arrayOf(labelValuePropType).isRequired,
  regions: PropTypes.arrayOf(labelValuePropType).isRequired,
  complexities: PropTypes.arrayOf(labelValuePropType).isRequired,
  filters: PropTypes.object.isRequired,
  isFiltering: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
}

export default PDJProjectFiltersView
