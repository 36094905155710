import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import ButtonBase from '@material-ui/core/ButtonBase'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import ArrowDown from '@public/svg/icons/arrow-down.svg'
import ArrowUp from '@public/svg/icons/arrow-up.svg'
import CloseIcon from '@public/svg/icons/close.svg'

import { currentAccountUser, currentProject } from '@lib/apollo/apolloCache'
import {
  COMMON_BLACK,
  COMMON_WHITE,
  GRAY_NES,
  ICON_GREY_PRIMARY,
  NAV_ITEM_HOVER_BACKGROUND,
  TEAL_DOLLHOUSE,
  TEAL_ROBOTS,
} from '@lib/colors'
import { serializeData } from '@lib/tracking'
import { hasPermission } from '@lib/userAuth'

import SvgLoader from '@components_pop/SvgLoader'

import PhaseIcon from './PhaseIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 20,
    border: `1px solid ${TEAL_ROBOTS}`,
    height: 35,
    position: 'relative',
    borderRadius: 3,
    cursor: 'pointer',
    minWidth: 200,
    color: TEAL_DOLLHOUSE,
  },
  phaseBody: {
    minWidth: 200,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: 'inherit',
    background: COMMON_WHITE,
    '& span': {
      margin: `0 ${theme.spacing(2)}px`,
      fontSize: 14,
      lineHeight: '1rem',
      fontWeight: 400,
      whiteSpace: 'nowrap',
    },
  },
  openedBody: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '& $currentSelected': {
      borderBottom: `1px solid ${GRAY_NES}`,
    },
  },
  phaseMenu: {
    position: 'absolute',
    visibility: 'hidden',
    opacity: 0,
    transition: '0.5s visibility, 0.5s opacity',
    left: -1,
    top: '100%',
    maxHeight: 'min(80vh, 600px)',
    overflowY: 'auto',
    width: 'calc(100% + 2px)',
    zIndex: 3, // greater than stickyHeader tables
    padding: '0px 1px',
    background: COMMON_WHITE,
    border: `1px solid ${TEAL_ROBOTS}`,
    borderTop: 'none',
    borderBottomRightRadius: 3,
    borderBottomLeftRadius: 3,
    '& ul': {
      padding: 0,
      background: COMMON_WHITE,
      listStyle: 'none',
      margin: 0,
    },
  },
  option: {
    justifyContent: 'start',
    height: 40,
    display: 'flex',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    '&:hover': {
      backgroundColor: NAV_ITEM_HOVER_BACKGROUND,
    },
    '&[aria-disabled="true"]': {
      '& > *:first-child': {
        borderColor: ICON_GREY_PRIMARY,
        '& svg path': {
          fill: ICON_GREY_PRIMARY,
        },
      },
      '& > *:last-child': {
        color: ICON_GREY_PRIMARY,
      },
    },
  },
  openedMenu: {
    visibility: 'visible',
    opacity: 1,
  },
  currentSelected: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    height: 20,
    width: 20,
    padding: 0,
    '& > span > div': {
      width: 15,
      height: 15,
    },
  },
  selectedDetails: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  detailsOptionsText: {
    width: '80%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  statusCount: {
    marginLeft: 'auto',
    fontSize: '.875rem',
  },
  disabled: {
    cursor: 'auto',
    svg: {
      display: 'none',
    },
  },
}))
const StatusCount = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.statusCount}>({children || '0'})</div>
}

StatusCount.propTypes = {
  children: PropTypes.node,
}

const PhaseSelect = ({
  onChange,
  statuses,
  currentStatusId,
  backgroundResetClass,
  filterMode = false,
  className,
  deprecatedOptions,
}) => {
  const findStatusOption = (statusId) => statuses?.find((s) => s.id === statusId) || {}
  const { isUserOnProjectTeam } = useReactiveVar(currentProject) || {}
  const accountUser = useReactiveVar(currentAccountUser)
  const classes = useStyles({ filterMode })
  const [open, setOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(findStatusOption(currentStatusId))
  const shouldBeReadOnly =
    filterMode || !isUserOnProjectTeam || !hasPermission.GEMBAH_ADMIN(accountUser)

  useEffect(() => {
    if (currentStatusId !== selectedOption.id) {
      setSelectedOption(findStatusOption(currentStatusId))
    }
  }, [currentStatusId])

  const handleToggle = () => setOpen(!open)
  const handleClose = () => setOpen(false)
  const handleChange = (opt) => {
    // we cannot add toast here, until we migrate all to pop
    setSelectedOption(opt)
    onChange(opt.id)
    handleClose()
  }
  const clearFilter = (e) => {
    e.stopPropagation()
    handleChange({})
  }

  const analyticsTargetId = filterMode ? 'projects-list_status-filter' : 'project_status-dropdown'
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div
        className={clsx(className, backgroundResetClass, classes.root, {
          [classes.openedBody]: open,
          [classes.disabled]: shouldBeReadOnly,
        })}
        data-tracking-info={serializeData({
          id: `${analyticsTargetId}_click`,
        })}
      >
        <div
          data-test-id="project-details-phase-button"
          className={classes.phaseBody}
          {...(!shouldBeReadOnly && { onClick: handleToggle })}
        >
          <div className={classes.currentSelected}>
            <div className={classes.selectedDetails}>
              <PhaseIcon phase={selectedOption.id} color={COMMON_BLACK} />
              <span>{selectedOption.name || 'All Statuses'}</span>
            </div>
            {filterMode && selectedOption.id && (
              <IconButton
                onClick={clearFilter}
                className={classes.iconButton}
                data-tracking-info={serializeData({
                  id: 'projects-list_clear-status-filter_click',
                })}
              >
                <SvgLoader {...CloseIcon} />
              </IconButton>
            )}
            <IconButton className={classes.iconButton}>
              {!shouldBeReadOnly && <SvgLoader {...(open ? ArrowUp : ArrowDown)} />}
            </IconButton>
          </div>
          <div
            className={clsx(backgroundResetClass, classes.phaseMenu, {
              [classes.openedMenu]: open,
            })}
          >
            <ul>
              {filterMode && (
                <ButtonBase
                  component="li"
                  className={classes.option}
                  onClick={() => handleChange({})}
                  value=""
                  data-tracking-info={serializeData({
                    id: `${analyticsTargetId}-option_click`,
                    option: 'all-statuses',
                  })}
                >
                  <PhaseIcon phase="" color={COMMON_BLACK} />
                  <span>All Statuses</span>
                </ButtonBase>
              )}
              {Object.values(statuses).map((option) => {
                const { name, id, count } = option
                return (
                  <ButtonBase
                    data-test-id={`project-details-phase-options-${id}`}
                    component="li"
                    className={classes.option}
                    onClick={() => handleChange(option)}
                    key={id}
                    data-tracking-info={serializeData({
                      id: `${analyticsTargetId}-option_click`,
                      option: id,
                    })}
                    disabled={
                      deprecatedOptions?.includes(selectedOption.id) && id === selectedOption.id
                    }
                  >
                    <PhaseIcon phase={id} />
                    <span className={classes.detailsOptionsText}>{name || 'Unknown'}</span>
                    {count && <StatusCount>{count}</StatusCount>}
                  </ButtonBase>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  )
}

PhaseSelect.propTypes = {
  className: PropTypes.string,
  backgroundResetClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  currentStatusId: PropTypes.string,
  filterMode: PropTypes.bool,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        count: PropTypes.number,
      }),
    })
  ),
  deprecatedOptions: PropTypes.array,
}

export default PhaseSelect
