import { useLazyQuery, useQuery } from '@apollo/client'
import React, { useState, useEffect } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { projectCategory, projectFilters, projectsStatusFilter } from '@lib/apollo/apolloCache'
import {
  PROJECT_STATUS,
  PROJECT_STATUS_DISPLAY_VALUES as DISPLAY_VALUES,
} from '@lib/constants/projects'
import { DESKTOP_CENTER_SPACE, MOBILE_BREAKPOINT } from '@lib/theme'
import withApollo from '@lib/withApollo'
import withAuth from '@lib/withAuth'

import { GET_PROJECTS, GET_PROJECT_LIST_STATUS_COUNTS } from '@graphql/project/queries'

import Loader from '@components_pop/Loader'
import PageContainer from '@components_pop/PageContainer'
import usePagination from '@components_pop/Pagination/hooks'
import ProjectList from '@components_pop/project/ProjectList'
import ProjectListControls from '@components_pop/project/ProjectListControls'
import ProjectListFilters from '@components_pop/project/ProjectListFilters'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
  },
  viewSelectorOption: {
    maxWidth: 30,
  },
  headerDivider: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  leftCardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardContent: {
    padding: 0,
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      padding: `30px ${DESKTOP_CENTER_SPACE}px`,
    },
  },
  memberAddButton: {
    padding: 0,
  },
  memberAddIcon: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  cardTitle: {
    margin: 0,
  },
  filterButton: {
    marginRight: theme.spacing(),
  },
}))

const ProjectsPage = () => {
  const classes = useStyles()
  const [page, setPage] = usePagination()
  // This renderLoader state is used to selectively render the loader for certain GET_PROJECTS query conditions.
  // For other GET_PROJECTS queries (i.e. table sorting), we don't want the jumpy experience of the loader/data render cycle.
  const [renderLoader, setRenderLoader] = useState(false)
  const [statuses, setStatuses] = useState([])
  const [isFiltering, setIsFiltering] = useState(false)
  const category = projectCategory()

  // eslint-disable-next-line no-unused-vars
  const { loading, error, data } = useQuery(GET_PROJECTS, {
    fetchPolicy: 'cache-and-network',
  })
  const [getProjectListStatusCount] = useLazyQuery(GET_PROJECT_LIST_STATUS_COUNTS, {
    onCompleted({ projectListStatusCounts }) {
      const mappedStatuses = Object.values(PROJECT_STATUS).reduce((all, statusValue) => {
        const matchingStatus = projectListStatusCounts.find(({ status }) => statusValue === status)

        if (!matchingStatus) return all

        const { status, count } = matchingStatus

        return [
          ...all,
          {
            id: status,
            name: DISPLAY_VALUES[status],
            count,
          },
        ]
      }, [])

      setStatuses(mappedStatuses)
    },
  })

  const toggleAllProject = () => {
    setRenderLoader(true)
  }

  const handleCategoryChange = (newCategory) => {
    if (newCategory === category) {
      return
    }
    setRenderLoader(true)
    projectsStatusFilter(null)
    if (newCategory === 'LEGACY') {
      getProjectListStatusCount()
    }
    projectCategory(newCategory)
  }

  const handlePageChange = (newPage) => {
    if (newPage === page) {
      return
    }
    setRenderLoader(true)
    setPage(newPage)
  }

  const { results: projects = [], totalCount = 0 } = data?.projects || {}

  const handleProjectStatusFilterChange = (filter) => {
    handlePageChange()
    projectsStatusFilter(filter || null) // must send null instead of empty string for all statuses
  }

  const handleFiltersChange = (newFilters) => {
    setIsFiltering(true)
    projectFilters(newFilters)
  }

  useEffect(() => {
    setPage(1)
  }, [])

  useEffect(() => {
    if (!loading) {
      setRenderLoader(false)
      setIsFiltering(false)
    }

    // if we are not on page 1 and we get 0 results after deleting
    // we redirect to a previous page
    if (data?.projects?.results.length === 0 && page !== 1) {
      setPage(page - 1)
    }
  }, [loading, error, data])

  return (
    <PageContainer
      title="Gembah - Projects"
      pageTitle="Projects"
      noMarginDesktop
      titleExtraContent={<ProjectListControls onToggle={toggleAllProject} />}
    >
      <Card elevation={0} className={classes.root}>
        <div className={classes.cardHeader}>
          <div className={classes.leftCardHeader} />
        </div>
        <ProjectListFilters
          category={category}
          statuses={statuses}
          filters={projectFilters()}
          isFiltering={isFiltering}
          onCategoryChange={handleCategoryChange}
          onProjectStatusFilterChange={handleProjectStatusFilterChange}
          onFiltersChange={handleFiltersChange}
        />
        <CardContent className={classes.cardContent}>
          <Grid container direction="column">
            <Grid item>
              <Loader
                loaderElementsCount={5}
                isLoadingContent={renderLoader || (loading && !data)}
                variant="project"
              >
                <ProjectList
                  page={page}
                  setPage={handlePageChange}
                  totalCount={totalCount}
                  projects={projects}
                  showingLegacy={category === 'LEGACY'}
                />
              </Loader>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </PageContainer>
  )
}

export default withApollo(withAuth(ProjectsPage))
