import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Button, makeStyles, Popover, Tooltip, Typography } from '@material-ui/core'

import ArrowDownIcon from '@public/svg/icons/arrow-down.svg'
import CloseIcon from '@public/svg/icons/close.svg'

import { S_NUFF, TEAL_DOLLHOUSE } from '@lib/colors'
import { makeEllipsisText } from '@lib/theme'

import SvgLoader from '@components_pop/SvgLoader'
import { PopCheckbox } from '@components_pop/forms/PopCheckBox'

const useStyles = makeStyles(() => ({
  projectFilter: {
    marginLeft: '10px',
  },
  button: {
    padding: '5px',
    fontSize: '13px',
    textTransform: 'none',
    '&.hasFilter': {
      background: S_NUFF,
    },
    '& > span > div': {
      marginLeft: 5,
      width: 15,
      height: 15,
    },
  },
  popover: {
    padding: '10px',
    minWidth: '250px',
  },
  popoverHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
    color: TEAL_DOLLHOUSE,
    fontSize: 16,
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    ...makeEllipsisText(250),
    cursor: 'pointer',
    color: TEAL_DOLLHOUSE,
    fontSize: 14,
    '&.disabled': {
      cursor: 'default',
    },
  },
  resetButton: {
    fontSize: '12px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: 13,
      height: 13,
      marginLeft: 10,
    },
  },
  checkboxWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

const ProjectFilter = ({ title, items, filter, isFiltering, onFilterChange }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleFilterChange = (filterValue, value) => {
    let newFilter = [...filter]
    if (newFilter) {
      if (value) {
        newFilter.push(filterValue)
      } else {
        newFilter.splice(newFilter.indexOf(filterValue), 1)
      }
    } else {
      newFilter = [filterValue]
    }

    onFilterChange(newFilter)
  }

  const buildItems = (itemsToBuild, disabled) => {
    return itemsToBuild.map((i) => (
      <div key={i.value} className={classes.checkbox}>
        <PopCheckbox
          checked={filter.includes(i.value)}
          onChange={(e) => handleFilterChange(i.value, e.target.checked)}
          disabled={disabled}
        />
        <Tooltip title={i.label ?? ''}>
          <Typography
            className={clsx({
              [classes.checkboxLabel]: true,
              disabled,
            })}
            onClick={disabled ? null : () => handleFilterChange(i.value, !filter.includes(i.value))}
          >
            {i.label}
          </Typography>
        </Tooltip>
      </div>
    ))
  }

  return (
    <div className={classes.projectFilter}>
      <Button
        className={clsx({
          [classes.button]: true,
          hasFilter: filter.length > 0,
        })}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {title}
        <SvgLoader {...ArrowDownIcon} />
      </Button>

      {!!anchorEl && (
        <Popover
          id={title}
          open
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            className: classes.popover,
          }}
        >
          <div className={classes.popoverHeader}>
            <div>{title}</div>
            {filter.length > 0 && (
              <div
                className={classes.resetButton}
                onClick={() => {
                  if (isFiltering) return
                  onFilterChange([])
                }}
              >
                reset <SvgLoader {...CloseIcon} />
              </div>
            )}
          </div>
          {items.length <= 5 && buildItems(items, isFiltering)}
          {items.length > 5 && (
            <div className={classes.checkboxWrapper}>
              <div>{buildItems(items.slice(0, Math.ceil(items.length / 2)), isFiltering)}</div>
              <div>
                {buildItems(items.slice(Math.ceil(items.length / 2), items.length), isFiltering)}
              </div>
            </div>
          )}
        </Popover>
      )}
    </div>
  )
}

ProjectFilter.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  filter: PropTypes.array.isRequired,
  isFiltering: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
}

export default ProjectFilter
