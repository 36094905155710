import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SatisfactionHappyIcon from '@public/svg/icons/satisfaction-user-happy-icon.svg'
import SatisfactionNeutralIcon from '@public/svg/icons/satisfaction-user-neutral-icon.svg'
import SatisfactionSadIcon from '@public/svg/icons/satisfaction-user-sad-icon.svg'

import { RootDialog, RootDialogActions, RootDialogContent } from '@dialog/RootDialog'

import PopButton from '@forms/PopButton'
import PopTextField from '@forms/PopTextField'

import { TEAL_DOLLHOUSE } from '@lib/colors'

import { EDIT_PROJECT_HEALTH } from '@graphql/npi/mutators'
import { GET_PROJECTS, GET_PROJECT_QUERY } from '@graphql/project/queries'

import SvgLoader from '@components_pop/SvgLoader'
import { TOAST_TYPES } from '@components_pop/Toast'
import GembahDialogTitle from '@components_pop/dialog/GembahDialogTitle'

import useToast from '@hooks/useToast'

import { PROJECT_HEALTH_STATUS_ENUM } from './constants'

const indicatorStyles = makeStyles(() => ({
  indicatorsWrapper: {
    display: 'flex',
    marginTop: '5px',
    marginBottom: '15px',
    flexDirection: 'row',
  },
  indicators: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  indicatorName: {
    minWidth: '60px',
    fontWeight: 'bold',
    color: TEAL_DOLLHOUSE,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicator: {
    width: 18,
    height: 18,
    cursor: 'pointer',
    opacity: 0.3,
    '&:hover': {
      opacity: 1,
    },
  },
  selected: {
    opacity: 1,
  },
}))

function IndicatorHealthSwitch({ label, name, control }) {
  const classes = indicatorStyles()

  return (
    <FormControl className={classes.indicatorsWrapper}>
      <div className={classes.indicatorName}>{label}</div>
      <Controller
        name={name}
        control={control}
        render={({ value, onChange }) => (
          <div className={classes.indicators}>
            <div className={classes.iconWrapper}>
              <div onClick={() => onChange(PROJECT_HEALTH_STATUS_ENUM.ON_TRACK)}>
                <SvgLoader
                  {...SatisfactionHappyIcon}
                  className={clsx(classes.indicator, {
                    [classes.selected]: value === PROJECT_HEALTH_STATUS_ENUM.ON_TRACK,
                  })}
                />
              </div>
            </div>
            <div className={classes.iconWrapper}>
              <div onClick={() => onChange(PROJECT_HEALTH_STATUS_ENUM.AT_RISK)}>
                <SvgLoader
                  {...SatisfactionNeutralIcon}
                  className={clsx(classes.indicator, {
                    [classes.selected]: value === PROJECT_HEALTH_STATUS_ENUM.AT_RISK,
                  })}
                />
              </div>
            </div>
            <div className={classes.iconWrapper}>
              <div onClick={() => onChange(PROJECT_HEALTH_STATUS_ENUM.CRITICAL_PROBLEM)}>
                <SvgLoader
                  {...SatisfactionSadIcon}
                  className={clsx(classes.indicator, {
                    [classes.selected]: value === PROJECT_HEALTH_STATUS_ENUM.CRITICAL_PROBLEM,
                  })}
                />
              </div>
            </div>
          </div>
        )}
      />
    </FormControl>
  )
}

IndicatorHealthSwitch.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object,
}

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  indicatorHeaders: {
    color: TEAL_DOLLHOUSE,
    display: 'flex',
    flexDirection: 'row',
    '& span': {
      minWidth: '60px',
    },
  },
  indicatorHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    fontSize: '12px',
  },
}))

const validationSchema = Yup.object().shape({
  time: Yup.string().required('Required'),
  cost: Yup.string().required('Required'),
  spec: Yup.string().required('Required'),
  communications: Yup.string().required('Required'),
  escalationId: Yup.string().max(120, `This field can't exceed 120 characters`),
})

export default function EditProjectHealth({ open, onClose, project }) {
  const classes = useStyles()
  const { addToast } = useToast()
  const projectSlug = project.slug
  const [editProjectHealth] = useMutation(EDIT_PROJECT_HEALTH, {
    refetchQueries: [
      {
        query: GET_PROJECT_QUERY,
        variables: { projectSlug },
      },
      {
        query: GET_PROJECTS,
      },
    ],
  })

  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cost: project.clientPulse.cost,
      time: project.clientPulse.time,
      spec: project.clientPulse.spec,
      communications: project.clientPulse.communications,
    },
  })

  const onSubmit = (data) => {
    editProjectHealth({
      variables: { ...data, projectId: project?.id },
    }).then(({ errors: err }) => {
      if (err) {
        addToast({
          message: `Failed to update indicators`,
          type: TOAST_TYPES.ERROR,
        })
        return
      }

      addToast({
        message: `The project indicators was updated successfully.`,
        type: TOAST_TYPES.SUCCESS,
      })
      onClose()
    })
  }

  return (
    <RootDialog open={open} onClose={onClose} fullWidth>
      <GembahDialogTitle className={classes.title} onClose={onClose}>
        Adjust Indicators
      </GembahDialogTitle>
      <RootDialogContent>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form} id="health-scores-form">
          <div className={classes.indicatorHeaders}>
            <span />
            <div className={classes.indicatorHeader}>
              <div>ON TRACK</div>
              <div>AT RISK</div>
              <div>CRITICAL</div>
            </div>
          </div>
          <IndicatorHealthSwitch label="Cost" name="cost" control={control} />
          <IndicatorHealthSwitch label="Time" name="time" control={control} />
          <IndicatorHealthSwitch label="Spec" name="spec" control={control} />
          <IndicatorHealthSwitch label="Comms" name="communications" control={control} />
          <PopTextField
            className={classes.textField}
            ref={register}
            label="Escalation ID"
            name="escalationId"
            error={!!errors.escalationId}
            helperText={errors.escalationId?.message}
          />
          <PopTextField
            ref={register}
            multiline
            classes={{ input: classes.textArea }}
            label="Reason for Score Change"
            name="changeReason"
            error={!!errors.changeReason}
            helperText={errors.changeReason?.message}
            placeholder="Issue, Mitigation, Expected resolution date, Notes..."
          />
        </form>
      </RootDialogContent>
      <RootDialogActions>
        <PopButton btnStyle="simple" size="small" onClick={onClose}>
          discard changes
        </PopButton>
        <PopButton
          data-test-id="project-details--add-checklistitem-submit"
          type="submit"
          form="health-scores-form"
          size="small"
          color="primary"
          autoFocus
        >
          update health
        </PopButton>
      </RootDialogActions>
    </RootDialog>
  )
}

EditProjectHealth.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  project: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    clientPulse: PropTypes.shape({
      time: PropTypes.string,
      cost: PropTypes.string,
      spec: PropTypes.string,
      communications: PropTypes.string,
    }),
  }),
}
