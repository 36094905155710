import { useMutation, useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'

import { currentProject } from '@lib/apollo/apolloCache'
import {
  DEPRECATED_PHASE_STATUSES,
  PROJECT_STATUS as STATUSES,
  PROJECT_STATUS_DISPLAY_VALUES as DISPLAY_VALUES,
} from '@lib/constants/projects'

import { SET_PROJECT_STATE } from '@graphql/npi/mutators'
import { EDIT_PROJECT } from '@graphql/project/mutators'
import { GET_PROJECT_LIST_STATUS_COUNTS, GET_PROJECT_QUERY } from '@graphql/project/queries'

import { NPI_PROJECT_STATES } from '@components_pop/ProjectDetailsNPI'

import PhaseSelect from './view'

const PhaseSelectContainer = ({ projectId }) => {
  const router = useRouter()
  const { projectSlug } = router.query
  const project = useReactiveVar(currentProject)
  const isNpiProject = !!project.npiTemplate

  const [updateProjectStatus] = useMutation(EDIT_PROJECT, {
    refetchQueries: [
      { query: GET_PROJECT_QUERY, variables: { projectSlug } },
      { query: GET_PROJECT_LIST_STATUS_COUNTS },
    ],
  })
  const [updateProjectState] = useMutation(SET_PROJECT_STATE, {
    refetchQueries: [
      { query: GET_PROJECT_QUERY, variables: { projectSlug } },
      { query: GET_PROJECT_LIST_STATUS_COUNTS },
    ],
  })

  const handleProjectStatusUpdate = (status) => {
    const updateMutation = isNpiProject ? updateProjectState : updateProjectStatus
    const variables = {
      projectId,
    }

    if (isNpiProject) {
      variables.projectState = status
    } else {
      variables.projectData = {
        status,
      }
    }

    updateMutation({
      variables,
    }).then(
      (/* res */) => {},
      (/* err */) => {}
    )
  }

  const currentStatusId = isNpiProject ? project.projectState : project.status
  const nonNpiStatuses = Object.values(STATUSES).map((statusId) => ({
    id: statusId,
    name: DISPLAY_VALUES[statusId],
  }))

  const statuses = isNpiProject ? NPI_PROJECT_STATES : nonNpiStatuses

  return (
    <PhaseSelect
      deprecatedOptions={isNpiProject ? [] : DEPRECATED_PHASE_STATUSES}
      currentStatusId={currentStatusId}
      onChange={handleProjectStatusUpdate}
      statuses={statuses}
      data-test-id="project-details-card-status"
    />
  )
}

PhaseSelectContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
}

export default PhaseSelectContainer
