import {
  faPencilRuler,
  faClipboardCheck,
  faIndustry,
  faTruck,
  faBoxCheck,
  faRepeatAlt,
  faMinusOctagon,
  faFileSearch,
  faArchive,
  faSparkles,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { memo } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { PRIMARY_TEXT_COLOR } from '@lib/colors'
import { PROJECT_STATUS as STATUS } from '@lib/constants/projects'
import { MOBILE_BREAKPOINT } from '@lib/theme'

import { NPI_PROJECT_STATES } from '@components_pop/ProjectDetailsNPI'

const useStyles = makeStyles((theme) => ({
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 20,
    minWidth: 20,
    height: 20,
    width: 20,
    fontSize: 12,
    backgroundColor: '#FFF',
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  newRfq: {
    borderColor: '#E5CB96',
  },
  design: {
    borderColor: '#E5CB96',
  },
  preProduction: {
    borderColor: '#547CB5',
  },
  production: {
    borderColor: '#547CB5',
  },
  shipping: {
    borderColor: '#547CB5',
  },
  fulfilled: {
    borderColor: '#547CB5',
  },
  reorder: {
    borderColor: '#547CB5',
  },
  onHold: {
    borderColor: '#BA6B6C',
  },
  research: {
    borderColor: '#E5CB96',
  },
  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    iconBox: {
      height: 20,
      width: 20,
      fontSize: 12,
    },
  },
}))

const PhaseIcon = ({ phase }) => {
  const classes = useStyles()
  const NPI_STATES = NPI_PROJECT_STATES.reduce((statesMap, state) => {
    return {
      ...statesMap,
      [state.id.toUpperCase()]: state.id,
    }
  }, {})

  switch (phase) {
    case STATUS.NEW_RFQ:
    case NPI_STATES.NEW:
      return (
        <div className={clsx(classes.iconBox, classes.newRfq)}>
          <FontAwesomeIcon icon={faSparkles} color={PRIMARY_TEXT_COLOR} />
        </div>
      )

    case STATUS.DESIGN:
    case STATUS.DESIGN_QUOTE:
    case NPI_STATES.ACTIVE:
      return (
        <div className={clsx(classes.iconBox, classes.design)}>
          <FontAwesomeIcon icon={faPencilRuler} color={PRIMARY_TEXT_COLOR} />
        </div>
      )

    case STATUS.PRE_PRODUCTION:
    case STATUS.PRICE_QUOTE:
    case STATUS.PRE_FLIGHT:
    case STATUS.SAMPLING:
      return (
        <div className={clsx(classes.iconBox, classes.preProduction)}>
          <FontAwesomeIcon icon={faClipboardCheck} color={PRIMARY_TEXT_COLOR} />
        </div>
      )

    case STATUS.PRODUCTION:
      return (
        <div className={clsx(classes.iconBox, classes.production)}>
          <FontAwesomeIcon icon={faIndustry} color={PRIMARY_TEXT_COLOR} />
        </div>
      )

    case STATUS.FULFILLED:
      return (
        <div className={clsx(classes.iconBox, classes.fulfilled)}>
          <FontAwesomeIcon icon={faTruck} color={PRIMARY_TEXT_COLOR} />
        </div>
      )

    case STATUS.SHIPPING:
      return (
        <div className={clsx(classes.iconBox, classes.shipping)}>
          <FontAwesomeIcon icon={faBoxCheck} color={PRIMARY_TEXT_COLOR} />
        </div>
      )

    case STATUS.REORDER:
      return (
        <div className={clsx(classes.iconBox, classes.reorder)}>
          <FontAwesomeIcon icon={faRepeatAlt} color={PRIMARY_TEXT_COLOR} />
        </div>
      )

    case STATUS.ON_HOLD:
    case NPI_STATES.ON_HOLD:
      return (
        <div className={clsx(classes.iconBox, classes.onHold)}>
          <FontAwesomeIcon icon={faMinusOctagon} color={PRIMARY_TEXT_COLOR} />
        </div>
      )

    case STATUS.ARCHIVED:
    case NPI_STATES.ARCHIVED:
      return (
        <div className={clsx(classes.iconBox, classes.onHold)}>
          <FontAwesomeIcon icon={faArchive} color={PRIMARY_TEXT_COLOR} />
        </div>
      )

    case STATUS.RESEARCH_KICKOFF:
    case STATUS.RESEARCH_IN_PROGRESS:
    case STATUS.RESEARCH_PRESENTED:
    case STATUS.RESEARCH_COMPLETED:
    case STATUS.RESEARCH:
      return (
        <div className={clsx(classes.iconBox, classes.research)}>
          <FontAwesomeIcon icon={faFileSearch} color={PRIMARY_TEXT_COLOR} />
        </div>
      )

    default:
      return (
        <div className={clsx(classes.iconBox, classes.newRfq)}>
          <FontAwesomeIcon icon={faPencilRuler} color={PRIMARY_TEXT_COLOR} />
        </div>
      )
  }
}

PhaseIcon.propTypes = {
  phase: PropTypes.string,
}

export default memo(PhaseIcon)
