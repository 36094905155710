import { useReactiveVar } from '@apollo/client'
import React from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { projectsSearch } from '@lib/apollo/apolloCache'

const ProjectListEmpty = () => {
  const hasFilters = !!useReactiveVar(projectsSearch)

  return (
    <TableRow data-test-id="project-dashboard-project-list--row-no-companies">
      <TableCell style={{ border: 'none' }} colSpan={6}>
        <span>
          {hasFilters
            ? `No results found for "${projectsSearch()}"`
            : 'You have no current projects'}
        </span>
      </TableCell>
    </TableRow>
  )
}

export default ProjectListEmpty
