import { useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'

import { SvgIcon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import PopButton from '@forms/PopButton'

import { currentAccountUser } from '@lib/apollo/apolloCache'
import useRoute from '@lib/useRoute'
import { hasPermission } from '@lib/userAuth'
import withPerms from '@lib/withPerms'

import useBreakpoint from '@hooks/useBreakpoint'

import MyAllProjectsToggle from './MyAllProjectsToggle'

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileAddBtn: {
    ...theme.makeMobileAddBtn,
    marginLeft: theme.spacing(1.5),
  },
}))

const ProjectListControls = ({ onToggle = () => {} }) => {
  const accountUser = useReactiveVar(currentAccountUser)
  const { isDesktop } = useBreakpoint()
  const [goTo] = useRoute()
  const classes = useStyles()

  const AddProject = () => (
    <PopButton className={classes.mobileAddBtn} onClick={() => goTo('/project/add')} size="small">
      <SvgIcon>
        <AddIcon />
      </SvgIcon>
    </PopButton>
  )

  const ProtectedAddProject = withPerms(AddProject)
  const hasTogglePerms = hasPermission.GEMBAH_ADMIN(accountUser)

  return (
    <div style={{ display: 'flex' }}>
      {isDesktop ? (
        <div className={classes.actionsContainer}>
          {hasTogglePerms && (
            <MyAllProjectsToggle onToggle={onToggle} trackingPrefix="projects-list" />
          )}
        </div>
      ) : (
        <>
          {hasTogglePerms && (
            <MyAllProjectsToggle onToggle={onToggle} trackingPrefix="projects-list" />
          )}
          <ProtectedAddProject permissionTest={hasPermission.GEMBAH_ADMIN} />
        </>
      )}
    </div>
  )
}

ProjectListControls.propTypes = {
  onToggle: PropTypes.func,
}

export default ProjectListControls
