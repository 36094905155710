import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import { currentAccountUser } from '@lib/apollo/apolloCache'
import {
  FOURTH_GREY,
  GRAYSCALE_MEDIUM,
  ICON_GREY_TERTIARY,
  PRIMARY_BLACK_BACKGROUND,
  PRIMARY_GREY_SEPARATOR,
  PRIMARY_LIGHT,
  PRIMARY_TEXT_COLOR,
} from '@lib/colors'
import { PROJECT_REGION_CHOICES_LABELS } from '@lib/constants/projects'
import { MOBILE_BREAKPOINT, makeEllipsisText } from '@lib/theme'
import { hasPermission } from '@lib/userAuth'

import EditProjectHealth from '@components_pop/project/ProjectHealth/EditProjectHealth'
import { ProjectStatusIcon } from '@components_pop/project/ProjectList/ProjectHealthIconStatus'

import useBreakpoint from '@hooks/useBreakpoint'

const DELIVERABLE_STATUS_DISPLAY_VALUES = {
  NOT_SUBMITTED: 'Uploaded',
  PENDING_REVIEW: 'Sent',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejeceted',
}

const useStyles = makeStyles((theme) => ({
  healthIndicatorsWrapper: {},
  contentTitle: {
    fontSize: '10px',
    lineHeight: '10px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    color: FOURTH_GREY,
    marginRight: theme.spacing(1),
  },
  healthIndicatorsContent: {
    display: 'flex',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '16px',
    color: PRIMARY_TEXT_COLOR,
    marginTop: theme.spacing(1),
    whiteSpace: 'normal',
  },
  healthIndicator: {
    marginRight: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  healthIndicatorLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    lineHeight: '10px',
    letterSpacing: '0.05em',
    color: FOURTH_GREY,
    marginBottom: '8px',
  },
  escalationsContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '15px',
  },
  escalation: {
    marginLeft: '20px',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    color: FOURTH_GREY,
  },
  deliverableContent: {
    fontSize: '16px',
    lineHeight: '20px',
    display: 'flex',
    marginTop: '5px',
    alignItems: 'center',
  },
  deliverableName: {
    ...makeEllipsisText(250),
  },
  deliverablePill: {
    marginLeft: '20px',
    border: '1px solid',
    borderColor: GRAYSCALE_MEDIUM,
    lineHeight: '15px',
    padding: '0px 4px',
    fontWeight: 'bold',
    borderRadius: '50px',
    fontSize: '11px',
    color: PRIMARY_BLACK_BACKGROUND,
  },
  box: {
    marginTop: '10px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  region: {
    marginLeft: '4px',
    color: GRAYSCALE_MEDIUM,
    fontSize: '10px',
    textTransform: 'uppercase',
    lineHeight: '10px',
  },
  subtitle: {
    fontSize: '10px',
    lineHeight: '10px',
    color: ICON_GREY_TERTIARY,
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
  },
  four: {
    flex: '1 1 40%',
  },
  three: {
    flex: '1 1 33%',
  },
  two: {
    flex: '1 1 20%',
  },
  milestone: {
    fontSize: '12px',
    lineHeight: '16px',
    color: PRIMARY_LIGHT,
  },
  date: {
    fontSize: '12px',
    lineHeight: '16px',
    color: PRIMARY_LIGHT,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerBox: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  outerBox: {
    padding: '0 10px',
    '&:first-child': {
      paddingLeft: '0',
    },
  },
  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    content: {
      flexWrap: 'nowrap',
      marginTop: '16px',
    },
    outerBox: {
      padding: '0 20px',
    },
    innerBox: {
      paddingRight: '20px',
      borderRight: `solid ${PRIMARY_GREY_SEPARATOR} 1px`,
    },
    four: {
      flex: 'unset',
      maxWidth: '250px',
      flexShrink: '0',
    },
    two: {
      flex: 'unset',
      maxWidth: '200px',
      flexShrink: '0',
      paddingRight: '20px',
    },
    projectDetailsWrapper: {
      display: 'flex',
    },
    leftContentWrapper: {
      padding: '10px',
      flex: '0 0 50%',
      cursor: 'pointer',
    },
  },
}))

const ProjectNpiExpansionContent = ({ rowItem }) => {
  const classes = useStyles()
  const { isDesktop } = useBreakpoint()
  const [editingIndicators, setEditingIndicators] = useState(false)
  const accountUser = useReactiveVar(currentAccountUser)
  const isGembah = hasPermission.GEMBAH(accountUser)

  const format = isDesktop ? 'MMMM Do, YYYY' : 'Do MMM YY'

  const getRegion = () => {
    return rowItem.region ? PROJECT_REGION_CHOICES_LABELS[rowItem.region] : 'Global'
  }

  const getMilestone = () => {
    return rowItem.currentNpiMilestone?.name ?? 'N/A'
  }

  const getMilestoneDueDate = () => {
    const dueAt = rowItem.currentNpiMilestone?.dueAt
    return dueAt ? moment(dueAt).format(format) : 'N/A'
  }

  const getDeliverableName = () => {
    return rowItem.currentNpiMilestone?.nextDeliverable?.name ?? 'N/A'
  }

  const getDeliverableDueDate = () => {
    const dueDate = rowItem.currentNpiMilestone?.nextDeliverable?.step?.dueDate
    return dueDate ? moment(dueDate).format(format) : ''
  }

  const getDeliverableStatus = () => {
    const status = rowItem.currentNpiMilestone?.nextDeliverable?.currentSubmission?.status
    return status ? DELIVERABLE_STATUS_DISPLAY_VALUES[status] : 'No Activity'
  }

  const getHealthIndicators = () => {
    return [
      { label: 'Time', status: rowItem.clientPulse?.time },
      { label: 'Cost', status: rowItem.clientPulse?.cost },
      { label: 'Spec', status: rowItem.clientPulse?.spec },
      { label: 'Comm', status: rowItem.clientPulse?.communications },
    ]
  }

  return (
    <Box
      margin={0.5}
      className={classes.projectDetailsWrapper}
      id={`project-details-wrapper-${rowItem.id}`}
    >
      {isGembah ? (
        <div className={classes.leftContentWrapper} onClick={() => setEditingIndicators(true)}>
          <div className={classes.contentTitle}>Health Indicators</div>

          <div className={classes.healthIndicatorsContent}>
            {getHealthIndicators().map((i) => (
              <div className={classes.healthIndicator} key={`health-indicator-${i.label}`}>
                <div className={classes.healthIndicatorLabel}>{i.label}</div>
                <ProjectStatusIcon status={i.status} hasTooltip />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <span className={classes.leftContentWrapper} />
      )}

      <Box className={classes.box}>
        <div className={classes.header}>
          <div className={classes.contentTitle}>REGION</div>
          <div className={classes.region}>{getRegion()}</div>
        </div>

        <div className={classes.content}>
          <div className={clsx({ [classes.four]: true, [classes.outerBox]: true })}>
            <div className={classes.subtitle}>Milestone:</div>

            <div
              className={clsx({
                [classes.milestone]: true,
                [classes.innerBox]: true,
              })}
            >
              {getMilestone()}
            </div>
          </div>

          <div className={classes.two}>
            <div className={classes.subtitle}>Due Date:</div>
            <div
              className={clsx({
                [classes.date]: true,
                [classes.innerBox]: true,
              })}
            >
              {getMilestoneDueDate()}
            </div>
          </div>

          <div>
            <div className={classes.subtitle}>Next Deliverable Due:</div>
            <div className={classes.deliverableContent}>
              <div className={classes.deliverableName}>{getDeliverableName()}</div>
              <div className={classes.deliverablePill}>{getDeliverableStatus()}</div>
            </div>
            <div className={classes.date}>{getDeliverableDueDate()}</div>
          </div>
        </div>
      </Box>
      {editingIndicators && (
        <EditProjectHealth
          open={editingIndicators}
          onClose={() => setEditingIndicators(false)}
          project={rowItem}
        />
      )}
    </Box>
  )
}

ProjectNpiExpansionContent.propTypes = {
  rowItem: PropTypes.any.isRequired,
}

export default ProjectNpiExpansionContent
