import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import Switch from '@material-ui/core/Switch'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import { myProjectsFilter } from '@lib/apollo/apolloCache'
import { TEAL_FURBY, GRAY_GAMEBOY, BOSTON_BLUE, ICE_BERG, HALF_BACKED } from '@lib/colors'
import { MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'

const useStyles = makeStyles((theme) => ({
  projectsToggleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  projectsToggleSide: {
    cursor: 'pointer',
    display: 'flex',
    color: TEAL_FURBY,
    fontWeight: 700,
    fontSize: '14px',
    alignItems: 'center',
    lineHeight: '17px',
    '& svg': {
      display: 'none',
      marginRight: 0,
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        marginRight: theme.spacing(1),
        display: 'block',
      },
    },
  },
  desktopLabel: {
    display: 'none',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      display: 'block',
    },
  },
  mobileLabel: {
    display: 'block',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      display: 'none',
    },
  },
  disabledProjectToggleSide: {
    color: HALF_BACKED,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
  },
}))

const AntSwitch = withStyles(() => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    margin: 10,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: BOSTON_BLUE,
    '&$checked': {
      transform: 'translateX(12px)',
      color: BOSTON_BLUE,
      '& + $track': {
        opacity: 1,
        backgroundColor: ICE_BERG,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 12,
    opacity: 1,
    backgroundColor: ICE_BERG,
    boxShadow: `inset 0px 0px 2px ${GRAY_GAMEBOY}`,
  },
  checked: {},
}))(Switch)

const ProjectListToggleSwitch = ({ onToggle = () => {}, trackingPrefix }) => {
  const classes = useStyles()
  const myProjects = useReactiveVar(myProjectsFilter)

  const handleToggle = (toggleBool) => {
    if (toggleBool === myProjects) return

    myProjectsFilter(toggleBool ?? !myProjects)
    onToggle()
  }

  return (
    <div className={classes.projectsToggleWrapper} data-test-id="toggle-my-projects">
      <div
        onClick={() => handleToggle(true)}
        className={clsx({
          [classes.projectsToggleSide]: true,
          [classes.disabledProjectToggleSide]: !myProjects,
        })}
      >
        <span className={classes.desktopLabel}>My projects </span>
        <span className={classes.mobileLabel}>MINE</span>
      </div>
      <AntSwitch
        data-test-id="projects-toggle-my"
        data-tracking-info={serializeData({
          id: `${trackingPrefix}_my-projects-filter-toggle_click`,
          wasEnabled: myProjects,
        })}
        checked={!myProjects}
        onChange={() => handleToggle()}
        name="projectsToggle"
        disableRipple
      />
      <div
        onClick={() => handleToggle(false)}
        className={clsx({
          [classes.projectsToggleSide]: true,
          [classes.disabledProjectToggleSide]: myProjects,
        })}
      >
        <span className={classes.desktopLabel}>All projects</span>
        <span className={classes.mobileLabel}>ALL</span>
      </div>
    </div>
  )
}

ProjectListToggleSwitch.propTypes = {
  onToggle: PropTypes.func,
  trackingPrefix: PropTypes.string,
}

export default ProjectListToggleSwitch
