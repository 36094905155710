import { valueLabelPairs } from '@lib/dataUtils'

export const PROJECT_HEALTH_STATUS_ENUM = {
  ON_TRACK: 'on-track',
  AT_RISK: 'at-risk',
  CRITICAL_PROBLEM: 'critical-problem',
  EMERGENCY: 'emergency',
}

export const PROJECT_HEALTH_STATUS_DISPLAY_VALUES = {
  [PROJECT_HEALTH_STATUS_ENUM.ON_TRACK]: 'On Track',
  [PROJECT_HEALTH_STATUS_ENUM.AT_RISK]: 'At Risk',
  [PROJECT_HEALTH_STATUS_ENUM.CRITICAL_PROBLEM]: 'Critical Problem',
  [PROJECT_HEALTH_STATUS_ENUM.EMERGENCY]: 'Emergency',
}

export const PROJECT_HEALTHS = valueLabelPairs(PROJECT_HEALTH_STATUS_DISPLAY_VALUES)

export const PROJECT_HEALTH_REASON_ENUM = {
  COMPLETED_ITEMS: 'completed-items',
  NO_URGENT_ITEMS: 'no-urgent-items',
  DUE_TOMORROW: 'due-tomorrow',
  NO_ITEMS: 'no-items',
  NO_DUE_DATE: 'no-due-date',
  OVERDUE: 'overdue',
  NEEDS_HELP: 'needs-help',
}

export const PROJECT_HEALTH_REASON_LIST = [
  {
    label: 'All checklist items are completed',
    value: PROJECT_HEALTH_REASON_ENUM.COMPLETED_ITEMS,
  },
  {
    label: 'Next step checklist item not due tomorrow and not overdue',
    value: PROJECT_HEALTH_REASON_ENUM.NO_URGENT_ITEMS,
  },
  {
    label: 'Next step checklist item due tomorrow',
    value: PROJECT_HEALTH_REASON_ENUM.DUE_TOMORROW,
  },
  {
    label: 'No checklist item present at all',
    value: PROJECT_HEALTH_REASON_ENUM.NO_ITEMS,
  },
  {
    label: 'Next step checklist item without a due date',
    value: PROJECT_HEALTH_REASON_ENUM.NO_DUE_DATE,
  },
  {
    label: 'Next step checklist item overdue',
    value: PROJECT_HEALTH_REASON_ENUM.OVERDUE,
  },
  {
    label: 'Needs help',
    value: PROJECT_HEALTH_REASON_ENUM.NEEDS_HELP,
  },
]
