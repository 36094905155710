import { useQuery } from '@apollo/client'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import {
  PROJECT_COMPLEXITY_CHOICES_OPTIONS,
  PROJECT_REGION_CHOICES_OPTIONS,
} from '@lib/constants/projects'

import { GET_NPI_MILESTONE_NAMES } from '@graphql/npi/queries'

import { PROJECT_HEALTHS } from '@components_pop/project/ProjectHealth/constants'

import PDJProjectFiltersView from './view'

const NPI_STATES = [
  { label: 'New', value: 'new' },
  { label: 'Active', value: 'active' },
  { label: 'On Hold', value: 'on_hold' },
  { label: 'Archived', value: 'archived' },
]

const DEFAULT_DEBOUNCE_TIME = 500

const PDJProjectFilters = ({ filters, isFiltering, onFiltersChange }) => {
  const [projectFilters, setProjectFilters] = useState(filters)
  const { data } = useQuery(GET_NPI_MILESTONE_NAMES)

  const getNpiMilestoneNames = () =>
    data?.npiMilestoneNames?.map((n) => {
      return { label: n.name, value: n.name }
    }) ?? []

  const debounceUpdateFilters = useRef(debounce(onFiltersChange, DEFAULT_DEBOUNCE_TIME))

  const handleResetFilters = () => {
    const newFilters = { ...projectFilters }
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = []
    })
    setProjectFilters(newFilters)
    onFiltersChange(newFilters)
  }

  const handleFilterChange = (filterType, newValue) => {
    const newFilters = { ...projectFilters }
    newFilters[filterType] = newValue
    setProjectFilters(newFilters)
    debounceUpdateFilters.current(newFilters)
  }

  useEffect(() => {
    const debounceMethod = debounceUpdateFilters.current
    return () => debounceMethod.cancel()
  }, [])

  return (
    <PDJProjectFiltersView
      milestones={getNpiMilestoneNames()}
      healths={PROJECT_HEALTHS}
      statuses={NPI_STATES}
      regions={PROJECT_REGION_CHOICES_OPTIONS}
      complexities={PROJECT_COMPLEXITY_CHOICES_OPTIONS}
      filters={projectFilters}
      isFiltering={isFiltering}
      onFilterChange={handleFilterChange}
      onResetFilters={handleResetFilters}
    />
  )
}

PDJProjectFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  isFiltering: PropTypes.bool.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
}

export default PDJProjectFilters
