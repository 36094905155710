export const PRODUCTION_STATUS = {
  R1_IN_RESEARCH: 'R1 - In research',
  R2_RFQ_SENT_TO_FACTORIES: 'R2 - RFQ sent to factories',
  Q1_WAITING_FOR_FACTORY_QUOTES: 'Q1 - Waiting for factory quotes',
  Q2_FIRST_QUOTE_RECEIVED: 'Q2 - First Quote Received',
  Q3_UPDATING_QUOTES: 'Q3 - Updating quotes',
  Q4_PREPARING_FINAL_QUOTE_SUMMARY: 'Q4 - Preparing final quote summary',
  Q5_QUOTE_CONFIRMATION: 'Q5 - Quote confirmation',
  S1_SAMPLING_CONFIRMED_WITH_FACTORY: 'S1 - Sampling confirmed with factory',
  S2_SAMPLING_PROCESS: 'S2 - Sampling process',
  S3_QC_CONFIRMED: 'S3 - QC Confirmed',
  S4_SAMPLE_QC_REPORT_COMPLETE: 'S4 - Sample QC report complete',
  S5_WAITING_FOR_SAMPLE_FEEDBACK: 'S5 - Waiting for sample feedback',
  S6_SAMPLE_APPROVED_BY_CLIENT: 'S6 - Sample approved by client',
  P1_CONFIRMING_PRODUCTION_WITH_FACTORY: 'P1 - Confirming production with factory',
  P2_IN_PRODUCTION: 'P2 - In Production',
  P3_PRODUCTION_APPROVED: 'P3 - Production Approved',
  P4_QC_CONFIRMATION_WITH_FACTORY: 'P4 - QC confirmation with factory',
  P5_QC_REPORT_BEING_PROCESSED: 'P5 - QC report being processed',
  P6_QC_REPORT_COMPLETE: 'P6 - QC report complete',
  P7_CONFIRMING_LOGISTICS_WITH_FF: 'P7 - Confirming logistics with FF',
  P8_PRODUCTS_IN_TRANSIT: 'P8 - Products in transit',
}

export const PRODUCTION_ISSUE_STATUS = {
  GOOD: 'Good',
  MEDIUM: 'Medium',
  BAD: 'Bad',
}

export const PRODUCTION_ISSUE_CATEGORY = {
  PRICE_CHANGE: 'Price change after in target/Update to Client',
  MOLD_SITUATION_CHANGE: 'Mold situation change',
  TIMELINE_CHANGES: 'Timeline changes',
  FACTORY_CHANGES_MATERIAL_OR_COLORS: 'Factory changes material or colors',
  FACTORY_UNWILLING_TO_HONOR_THEIR_PRICE_SAMPLE_PRODUCTION_TIME:
    'Factory unwilling to honor their price, sample, production time',
  MEETING_WITH_DESIGNER_OR_FACTORY: 'Meeting with the Designer/Factory.',
  FACTORY_VISITING: 'Factory visiting',
  OVERDUE_CHECKLIST_ITEM: 'Overdue Checklist Item',
  NO_DUE_DATE_CHECKLIST_ITEM: 'Checklist item with no date',
}

export const DEFAULT_PROD_SUMMARY_STATE = {
  statusConfirmed: false,
  updatedAt: '',
  status: PRODUCTION_STATUS.R1_IN_RESEARCH,
  health: PRODUCTION_ISSUE_STATUS.GOOD,
  category: '',
  gpsd: '',
}
