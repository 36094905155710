import PropTypes from 'prop-types'
import React from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import FireIcon from '@public/svg/icons/fire-icon.svg'
import SatisfactionHappyIcon from '@public/svg/icons/satisfaction-user-happy-icon.svg'
import SatisfactionNeutralIcon from '@public/svg/icons/satisfaction-user-neutral-icon.svg'
import SatisfactionSadIcon from '@public/svg/icons/satisfaction-user-sad-icon.svg'

import { WARNING_LIGHT, ERROR_MAIN, GRAYSCALE_MEDIUM, SUCCESS_MAIN } from '@lib/colors'

import SvgLoader from '@components_pop/SvgLoader'
import {
  PROJECT_HEALTH_REASON_LIST,
  PROJECT_HEALTH_STATUS_ENUM,
  PROJECT_HEALTH_STATUS_DISPLAY_VALUES,
} from '@components_pop/project/ProjectHealth/constants'

const useStyles = makeStyles({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& $onTrack, & $atRisk, & $critical, & $noStatus': {
      borderRadius: '50%',
    },
  },
  onTrack: {
    background: SUCCESS_MAIN,
  },
  atRisk: {
    background: WARNING_LIGHT,
  },
  critical: {
    background: ERROR_MAIN,
  },
  noStatus: {
    background: GRAYSCALE_MEDIUM,
  },
  icon: {
    width: 18,
    height: 18,
  },
})

const getProjectReason = (reason) =>
  reason && PROJECT_HEALTH_REASON_LIST.find((item) => item.value === reason)?.ariaLabel

export const getProjectHealthReason = (projectHealth) => getProjectReason(projectHealth?.reason)

export const getStatus = (projectHealth) =>
  projectHealth && PROJECT_HEALTH_STATUS_DISPLAY_VALUES[projectHealth.status]

export const ProjectStatusIcon = ({ status, reason, hasTooltip, size = '18px' }) => {
  const classes = useStyles()
  const style = {
    width: size,
    height: size,
  }

  const getIcon = () => {
    let ariaLabel

    switch (status) {
      case PROJECT_HEALTH_STATUS_ENUM.ON_TRACK:
        return <SvgLoader {...SatisfactionHappyIcon} className={classes.icon} />

      case PROJECT_HEALTH_STATUS_ENUM.AT_RISK:
        return <SvgLoader {...SatisfactionNeutralIcon} className={classes.icon} />

      case PROJECT_HEALTH_STATUS_ENUM.CRITICAL_PROBLEM:
        return <SvgLoader {...SatisfactionSadIcon} className={classes.icon} />

      case PROJECT_HEALTH_STATUS_ENUM.EMERGENCY:
        ariaLabel = PROJECT_HEALTH_REASON_LIST.find(
          (item) => item.value === PROJECT_HEALTH_STATUS_ENUM.NEEDS_HELP
        )?.label

        return (
          <span aria-label={ariaLabel}>
            <SvgLoader {...FireIcon} className={classes.icon} />
          </span>
        )

      default:
        return <div className={classes.noStatus} style={style} />
    }
  }

  const getToolTipTitle = () =>
    `${PROJECT_HEALTH_STATUS_DISPLAY_VALUES[status]}${
      reason ? `: ${getProjectReason(reason)}` : ''
    }`

  return (
    <div className={classes.iconWrapper}>
      {hasTooltip && status ? (
        <Tooltip title={getToolTipTitle() ?? ''}>{getIcon()}</Tooltip>
      ) : (
        getIcon()
      )}
    </div>
  )
}

ProjectStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  reason: PropTypes.string,
  hasTooltip: PropTypes.bool,
  size: PropTypes.any,
}

const ProjectHealthIconStatus = ({ projectHealth, hasTooltip, size = '18px' }) => {
  return (
    <ProjectStatusIcon
      status={projectHealth.status}
      reason={projectHealth.reason}
      hasTooltip={hasTooltip}
      size={size}
    />
  )
}

ProjectHealthIconStatus.propTypes = {
  projectHealth: PropTypes.shape({ status: PropTypes.string, reason: PropTypes.string }),
  hasTooltip: PropTypes.bool,
  size: PropTypes.any,
}

export default ProjectHealthIconStatus
